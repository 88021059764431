document.addEventListener("DOMContentLoaded", function () {

  const notyf = new Notyf({
    duration: 5000,
    position: { x: "right", y: "bottom" },
    types: [
      {
        type: "error",
        background: "indianred",
        duration: 6000,
      },
    ],
  });

  // 1. Sticky Header Functionality
  window.onscroll = function () {
    handleStickyHeader();
  };

  function handleStickyHeader() {
    const header = document.getElementById("truffe-header");
    if (!header) return;

    if (window.pageYOffset > 40) {
      header.classList.add("hfe-sticky");
    } else {
      setTimeout(function () {
        header.classList.remove("hfe-sticky");
      }, 100);
    }
  }

  // 2. Toggle Show Content for Widgets
  document.querySelectorAll(".truffle-plus, .truffle-plus-transition").forEach(function (element) {
    element.addEventListener("click", function () {
      const widget = element.closest(
        element.classList.contains("truffle-plus")
          ? ".truffle-widget"
          : ".custom-transition-widget"
      );
      
      if (widget) {
        widget.classList.toggle("show-content");
      }
      element.classList.toggle("close");
    });
  });

  // 3. Sidebar Toggle Functions
  function toggleSidebar(sidebarId, backgroundId) {
    const sidebar = document.getElementById(sidebarId);
    const background = document.getElementById(backgroundId);
    if (!sidebar || !background) return;

    sidebar.style.right = sidebar.style.right === "0px" ? "-400px" : "0px";
    background.classList.toggle("show");
  }

  window.toggleMobileMenu = function () {
    toggleSidebar("mobile-menu-sidebar", "background_login");
  };

  window.toggleLoginSidebar = function () {
    toggleSidebar("login-sidebar", "background_login");
  };

  window.toggleRegisterSidebar = function () {
    toggleSidebar("register-sidebar", "background_login");
  };

  window.toggleForgetSidebar = function () {
    toggleSidebar("forget-sidebar", "background_login");
  };

  // 4. Login Form Submission (both default and checkout)
  function handleLoginFormSubmit(formId) {
    const loginForm = document.getElementById(formId);
    if (!loginForm) return;
    const emailInput = loginForm.querySelector("input[name='email']");
    const passwordInput = loginForm.querySelector("input[name='password']");

    loginForm.addEventListener("submit", function (e) {
      e.preventDefault();
      clearErrors(loginForm);

      let hasErrors = false;
      if (!validateEmail(emailInput.value)) {
        showError(emailInput, my_translations.fielderror);
        hasErrors = true;
      }

      if (passwordInput.value.trim() === "") {
        showError(passwordInput, my_translations.fielderror);
        hasErrors = true;
      }

      if (hasErrors) return;
      handleLoginSubmit(loginForm);
    });
  }

  handleLoginFormSubmit("login-form");
  handleLoginFormSubmit("login-form-checkout");

  // Handle Login Submit
  function handleLoginSubmit(form) {
    const formData = new FormData(form);
    const errorMessageDiv = form.querySelector(".error-message");
    const spinner = form.querySelector(".spinner");
    const submitButton = form.querySelector("button");
    formData.append("action", "custom_login");

    if (spinner) spinner.classList.add("show");
    submitButton.disabled = true;

    fetch(ajax_object.ajaxurl, {
      method: "POST",
      credentials: "same-origin",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          notyf.success(data.data.message);
          setTimeout(function () {
            window.location.reload();
          }, 100);
        } else {
          notyf.error(data.data.message);
          spinner.classList.remove("show");
          submitButton.disabled = false;
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  // 5. Register Form Submission with Validation
  const registerForm = document.getElementById("register-form");
  const gdprCheckbox = document.getElementById("accept_pp");
  const submitButton = document.getElementById("register-validate");

  if (registerForm && gdprCheckbox && submitButton) {
    const spinner = registerForm.querySelector(".spinner");

    gdprCheckbox.addEventListener("change", function () {
      submitButton.disabled = !gdprCheckbox.checked;
    });

    registerForm.addEventListener("submit", function (e) {
      e.preventDefault();
      handleRegisterSubmit(registerForm, submitButton, spinner);
    });
  }

  function handleRegisterSubmit(form, submitButton, spinner) {
    clearErrors(form);
    const fields = [
      { field: "field_email", validate: validateEmail, message: my_translations.fielderror },
      { field: "motpasse", validate: validatePassword, message: my_translations.fielderror },
      { field: "billing_first_name", validate: isNotEmpty, message: my_translations.fielderror },
      { field: "billing_last_name", validate: isNotEmpty, message: my_translations.fielderror },
      { field: "billing_address_1", validate: isNotEmpty, message: my_translations.fielderror },
      { field: "billing_city", validate: isNotEmpty, message: my_translations.fielderror },
      { field: "billing_postcode", validate: isNotEmpty, message: my_translations.fielderror },
    ];

    let hasErrors = false;

    fields.forEach(({ field, validate, message }) => {
      const input = document.getElementById(field);
      if (!validate(input.value)) {
        showError(input, message);
        hasErrors = true;
      }
    });

    if (hasErrors) return;

    const formData = new FormData(form);
    formData.append("action", "custom_register");

    spinner.classList.add("show");
    submitButton.disabled = true;

    fetch(ajax_object.ajaxurl, {
      method: "POST",
      credentials: "same-origin",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          if (formData.get("newsletter") === "on") {
            saveNewsletterEmail(formData.get("email"));
          } else {
           window.location.reload();
          }
        } else {
          notyf.error(data.data.message);
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  function saveNewsletterEmail(email) {
    const formData = new FormData();
    formData.append("action", "save_newsletter_email");
    formData.append("email", email);
  
    fetch(ajax_object.ajaxurl, {
      method: "POST",
      credentials: "same-origin",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          window.location.reload();
        } else {
          console.error("Error:", data.message);
          window.location.reload();
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("An error occurred while subscribing to the newsletter.");
      });
  }

  // 6. Password Reset Form
  const forgetPasswordForm = document.getElementById("forget-password-form");
  const emailInput = document.getElementById("user_email");

  if (forgetPasswordForm && emailInput) {
    forgetPasswordForm.addEventListener("submit", function (e) {
      e.preventDefault();

      clearErrors(forgetPasswordForm);
      if (!validateEmail(emailInput.value)) {
        showError(emailInput, my_translations.fielderror);
        return;
      }

      handleForgetPasswordSubmit(forgetPasswordForm);
    });
  }

  function handleForgetPasswordSubmit(form) {
    const formData = new FormData(form);
    formData.append("action", "custom_forget_password");

    fetch(ajax_object.ajaxurl, {
      method: "POST",
      credentials: "same-origin",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          notyf.success(data.data.message);
          form.style.display = "none";
        } else {
          notyf.error(data.data.message);
        }
      })
      .catch((error) => console.error("Error:", error));
  }

  // 7. Coupon Apply
  const applyCouponButton = document.querySelector('button[name="apply_coupon"]');

  if (applyCouponButton) {
    applyCouponButton.addEventListener("click", function (e) {
      e.preventDefault();
      handleApplyCoupon();
    });
  }

  function handleApplyCoupon() {
    const spinner = document.getElementById("spinner_coupon");
    const couponCode = document.querySelector("#coupon_code").value;
    const formData = new FormData();
    formData.append("action", "apply_coupon");
    formData.append("coupon_code", couponCode);

    if (spinner) spinner.classList.add("show");

    fetch(ajax_object.ajaxurl, {
      method: "POST",
      body: formData,
    })
    .then((response) => response.json())
    .then((data) => {
      if (spinner) spinner.classList.remove("show");
      console.log(data.message);
      if (data.success) {
        notyf.success(data.data.message);
        window.location.reload();
      } else {
        notyf.error(data.data.message);
      }
    })
      .catch((error) => console.error("Error:", error));
  }

  // 8. Modal Handling
  const modal = document.getElementById("truffle-modal");
  const btn = document.querySelector(".truffle-plus-read");
  const span = document.querySelector(".close-modal");

  if (btn) {
    btn.addEventListener("click", function () {
      modal.style.display = "block";
    });
  }

  if (span) {
    span.addEventListener("click", function () {
      modal.style.display = "none";
    });
  }

  window.addEventListener("click", function (event) {
    if (event.target == modal) {
      modal.style.display = "none";
    }
  });

  // 9. Login Icon Dropdown Handling
  const loginIconWrappers = document.querySelectorAll(".custom-icon-login");

  loginIconWrappers.forEach(function (wrapper) {
    wrapper.addEventListener("click", function (e) {
      e.preventDefault();
      const dropdown = this.querySelector(".dropdown-menu");

      document.querySelectorAll(".dropdown-menu").forEach(function (menu) {
        if (menu !== dropdown) {
          menu.style.display = "none";
        }
      });

      dropdown.style.display = dropdown.style.display === "block" ? "none" : "block";
    });
  });

  // Close dropdown if clicking outside
  document.addEventListener("click", function (e) {
    loginIconWrappers.forEach(function (wrapper) {
      if (!wrapper.contains(e.target)) {
        const dropdown = wrapper.querySelector(".dropdown-menu");
        if (dropdown) {
          dropdown.style.display = "none";
        }
      }
    });
  });

  // Stop dropdown from closing when clicking on links inside it
  document.querySelectorAll(".dropdown-menu a").forEach(function (link) {
    link.addEventListener("click", function (e) {
      e.stopPropagation();
    });
  });

  // 10. Helper Functions
  function showError(input, message) {
    input.classList.add("error");
    let errorMessage = input.parentNode.querySelector(".error-field");
    if (!errorMessage) {
      errorMessage = document.createElement("span");
      errorMessage.classList.add("error-field");
      errorMessage.textContent = message;
      input.parentNode.appendChild(errorMessage);
    }
  }

  function clearErrors(form) {
    const inputs = form.querySelectorAll(".error");
    inputs.forEach((input) => {
      input.classList.remove("error");
    });

    const errorMessages = form.querySelectorAll(".error-field");
    errorMessages.forEach((message) => {
      message.remove();
    });
  }

  function validateEmail(email) {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  }

  function isNotEmpty(value) {
    return value.trim() !== "";
  }

  function validatePassword(password) {
    return password.trim().length >= 6;
  }

  const togglePasswordIcons = document.querySelectorAll(".toggle-password");

  togglePasswordIcons.forEach(function (icon) {
    icon.addEventListener("click", function () {
      const input = this.parentNode.querySelector("input");
      if (input.type === "password") {
        input.type = "text";
        this.classList.add("closed");
      } else {
        input.type = "password";
        this.classList.remove("closed");
      }
    });
  });
});


document.addEventListener("DOMContentLoaded", function () {
  // Fonction pour gérer l'état du bouton de validation
  function handleGdprValidation() {
    var gdprCheckbox = document.querySelector("#gdpr");
    var checkoutSubmitButton = document.querySelector("#place_order");

    if (gdprCheckbox && checkoutSubmitButton) {
      checkoutSubmitButton.disabled = !gdprCheckbox.checked;

      gdprCheckbox.addEventListener("change", function () {
        checkoutSubmitButton.disabled = !gdprCheckbox.checked;
      });
    }
  }

  handleGdprValidation();

  jQuery(document.body).on("updated_checkout", function () {
    handleGdprValidation();
  });
});

document.addEventListener("DOMContentLoaded", function () {
  var checkbox = document.getElementById("ship_to_different_address");
  var shippingFields = document.querySelector(".shipping-address-fields");

  if (checkbox) {
    checkbox.addEventListener("change", function () {
      if (checkbox.checked) {
        shippingFields.style.display = "block";
      } else {
        shippingFields.style.display = "none";
      }
    });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  // Fonction pour gérer l'état du bouton de validation
  function handleGdprValidation() {
    var gdprCheckbox = document.querySelector("#gdpr");
    var checkoutSubmitButton = document.querySelector("#place_order");

    if (gdprCheckbox && checkoutSubmitButton) {
      checkoutSubmitButton.disabled = !gdprCheckbox.checked;

      gdprCheckbox.addEventListener("change", function () {
        checkoutSubmitButton.disabled = !gdprCheckbox.checked;
      });
    }
  }

  handleGdprValidation();

  jQuery(document.body).on("updated_checkout", function () {
    handleGdprValidation();
  });
});

document.addEventListener("DOMContentLoaded", function () {
  var checkbox = document.getElementById("ship_to_different_address");
  var shippingFields = document.querySelector(".shipping-address-fields");

  if (checkbox) {
    checkbox.addEventListener("change", function () {
      if (checkbox.checked) {
        shippingFields.style.display = "block";
      } else {
        shippingFields.style.display = "none";
      }
    });
  }
});

document.addEventListener("DOMContentLoaded", function () {
  const couponInput = document.getElementById("coupon_code");
  const applyCouponButton = document.querySelector(
    "button[name='apply_coupon']"
  );

  // Vérifier si le champ couponInput existe avant d'ajouter des événements
  if (couponInput && applyCouponButton) {
    // Fonction pour vérifier si l'input est vide ou non
    function toggleButtonState() {
      if (couponInput.value.trim() !== "") {
        applyCouponButton.disabled = false; // Activer le bouton si l'input n'est pas vide
      } else {
        applyCouponButton.disabled = true; // Désactiver le bouton si l'input est vide
      }
    }

    // Ajouter un événement 'input' pour surveiller les changements dans le champ
    couponInput.addEventListener("input", toggleButtonState);

    // Appeler la fonction une fois au chargement pour définir l'état initial du bouton
    toggleButtonState();
  }
});


document.querySelectorAll('.custom-person-widget-plus').forEach(function(element) {
 
  // Toggle opacity lors du clic sur .custom-person-widget-plus
  element.addEventListener('click', function() {
    const overlay = this.closest('.custom-person-widget').querySelector('.overlay');
    
    // Basculer l'opacité entre 0 et 1
    if (overlay.style.opacity === '1') {
      overlay.style.opacity = '0';
      this.classList.remove('active'); // Retirer la classe 'active' quand l'opacité est 0
    } else {
      overlay.style.opacity = '1';
      this.classList.add('active'); // Ajouter la classe 'active' quand l'opacité est 1
    }
  });
});